.Loader-Container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(105, 93, 98, .5) 29%, rgba(9, 12, 15, .6) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

img.Loader-Logo {
  max-height: 50%;
  animation: heartbeat 1.5s ease-in-out 0s infinite both;
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out
  }

  10% {
    transform: scale(.91);
    animation-timing-function: ease-in
  }

  17% {
    transform: scale(.98);
    animation-timing-function: ease-out
  }

  33% {
    transform: scale(.87);
    animation-timing-function: ease-in
  }

  45% {
    transform: scale(1);
    animation-timing-function: ease-out
  }
}