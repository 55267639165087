.App-Main-Container {
  display: flex;
  flex-direction: column;
  position: relative;
  animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.App-Header {
  height: 64px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  backdrop-filter: saturate(180%) blur(20px);
  position: fixed;
  top: 0;
  animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  z-index: 1000;
}

img.App-Header-Logo {
  width: 10%;
}

.Socials-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

a,
a:hover,
a:visited {
  color: var(--tertiaryColor);
  cursor: pointer;
}

.Socials-Item-Container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Social-Link:hover {
  color: var(--secondaryColor);
  background: var(--tertiaryColor);
  cursor: pointer;
}

.Social-Link {
  color: var(--tertiaryColor);
  background: var(--secondaryColorOpacity2);
  padding: 0px;
  border-radius: 5px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  transition: 0.25s all ease-in;
  cursor: pointer;
}

svg.Social-Item {
  width: 24px;
}

.App-Header-Title {
  font-family: papyrus;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation-delay: 0.25s;
  z-index: 3;
  /* text-shadow: 2px 2px 10px var(--tertiaryColor); */
  flex: 2;

}

.App-Header-Title a {
  color: var(--tertiaryColor) !important;
  text-decoration: none;
}

#App-First-Screen {
  bottom: 0;
  width: 80%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: rgba(255, 255, 255, 0.25);
  background: radial-gradient(circle, rgba(105, 93, 98, 0.5) 29%, rgba(9, 12, 15, 0.6) 100%);
  margin: auto;

}

.App-BG-Blur {
  background-position-x: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  width: 140%;
  background-size: 100%;
  transition: background-size 0.5s ease-in;
  z-index: -1;
}

.Tile-Title {
  background: var(--secondaryColorOpacity);
  backdrop-filter: blur(3px);
  width: 80%;
  text-align: center;
  color: var(--tertiaryColor);
  font-size: 18px !important;
  color: var(--primaryColor) !important;
  overflow: hidden;
  transition: 0.25s all ease-out;
  animation: none !important;
  border: 2px solid;
}

.App-Fit-Blur {
  width: 100% !important;
}

.App-Tile-Darken {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(105, 93, 98, 0.5) 29%, rgba(9, 12, 15, 0.6) 100%);
  transition: 0.33s all ease-in;
}

.Tile {
  position: relative;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  min-width: 50%;
  flex: 1;

}

.Tile:hover .Tile-Title {
  transform: scale(1.25, 1.25);
  background: var(--secondaryColor);

}

.Tile:hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.Tile:hover .App-Tile-Darken {
  opacity: 0;
}

.Tile:hover .App-BG-Blur {
  background-size: 110%;
}

.App-Tiles-Container {
  width: 100%;
  padding: 0 10%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 1;
  background: radial-gradient(circle, rgba(105, 93, 98, 0.5) 29%, rgba(9, 12, 15, 0.6) 100%)
}

.App-BG-Blur2 {
  position: absolute;
  width: 80%;
  height: 100%;
  background: rgba(255, 255, 255, 0.25);
  background: radial-gradient(circle, rgba(105, 93, 98, 0.5) 29%, rgba(9, 12, 15, 0.6) 100%);
}

.BlurLeft {
  /* background: rgba(200, 200, 200, 0.75); */
  position: fixed;
  width: 10%;
  height: 100%;
  z-index: 1;
  filter: blur(2px);
  backdrop-filter: saturate(180%) blur(2px);

}

.BlurRight {
  /* background: rgba(200, 200, 200, 0.75); */
  position: fixed;
  width: 10%;
  right: 0;
  height: 100%;
  z-index: 1;
  filter: blur(2px);
  backdrop-filter: saturate(180%) blur(2px);

}

.App-First-Screen-Contents {
  display: flex;
  flex-direction: row;
  max-width: 80%;
  justify-content: center;
  align-items: center;
}

img.App-Logo-Home {
  /* width: 10px; */
  z-index: 2;
  /* height: calc(100vh - 200px); */
  /* max-width: 400px; */
  flex: 1;
  width: 80%;
  max-width: 500px;
  animation: slide-in-elliptic-left-bck 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-elliptic-left-bck {
  0% {
    transform: translateX(-800px) rotateY(-30deg) scale(6.5);
    transform-origin: 200% 50%;
    opacity: 0;
  }

  100% {
    transform: translateX(0) rotateY(0) scale(1);
    transform-origin: -600px 50%;
    opacity: 1;
  }
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out
  }

  10% {
    transform: scale(.91);
    animation-timing-function: ease-in
  }

  17% {
    transform: scale(.98);
    animation-timing-function: ease-out
  }

  33% {
    transform: scale(.87);
    animation-timing-function: ease-in
  }

  45% {
    transform: scale(1);
    animation-timing-function: ease-out
  }
}

@keyframes swirl-in-fwd {
  0% {
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }

  100% {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}

.App-Main-Title-Container {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  min-width: 600px;
}

.App-Logo-Home-Ctn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
}

.App-Main-Title {
  color: var(--tertiaryColor);
  font-family: papyrus;
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 600;
  /* top: 0; */
  /* position: absolute; */
  /* height: 100%; */
  line-height: 50px;
  /* height: 50px; */
  margin-bottom: 16px;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation-delay: 0.25s;
  z-index: 3;
  text-shadow: 2px 2px 10px var(--secondaryColor);
  /* position: relative; */
}

.App-Main-Subtitle {
  color: var(--primaryColor);
  z-index: 9999999999;
  font-size: 24px;
  font-weight: bold;
  border-top: 2px solid var(--secondaryColor);
  padding-top: 12px;
  width: 80%;
  text-align: center;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation-delay: 0.5s;
  font-family: papyrus;
}

.App-Main-Legend {
  background: rgba(0, 0, 0, 0.125);
  z-index: 100;
  color: var(--primaryColor);
  width: 80%;
  margin-top: 8px;
  padding: 0 16px;
  animation: fade-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.75s both;
  backdrop-filter: blur(5px);
}

@keyframes flip-in-hor-bottom {
  0% {
    transform: rotateX(80deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0);
    opacity: 1;
  }
}

.App-Button-Circle {
  background: var(--tertiaryColor);
  transition: 0.25s all ease-out;
  font-size: 32px;
  color: var(--secondaryColor);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  /* margin: auto; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 8px;
  left: calc(50% - 24px);
  animation: swirl-in-fwd 0.6s ease-out both,
    heartbeat 1.5s ease-in-out 0.75s infinite both;
}

.Description-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100vh;
  justify-content: center;
  /* padding: 32px 0; */
  min-width: 80%;
  background: rgba(255, 255, 255, 0.25);
  background: radial-gradient(circle, rgba(105, 93, 98, 0.5) 29%, rgba(9, 12, 15, 0.6) 100%);
  width: 80%;
  max-width: 80%;

  margin: auto;
}

.App-Contents-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0
}

.Item-Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
}

.Description-Text p b,
.Description-Text p strong,
.Description-Text strong {
  font-family: papyrus;
  color: var(--tertiaryColor);
  font-size: 20px;
  background: var(--secondaryColorOpacity);
  border-radius: 5px;
  padding: 0 8px;
  max-width: fit-content;
  margin: auto;
  margin-bottom: 4px;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Description-Text a {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}

.Description-Text p {
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.Description-Text {
  background: rgba(0, 0, 0, 0.125);
  padding: 0 16px;
  color: var(--primaryColor);
  width: 100%;
  max-width: 1000px;
  padding: 8px 32px;
  backdrop-filter: blur(15px);
  text-align: center;
}

.Description-Text img {
  width: 100% !important;
  height: auto !important;
}

.Title {
  color: var(--tertiaryColor);
  font-family: papyrus;
  text-transform: uppercase;
  font-size: 32px;
  height: 100%;
  line-height: 50px;
  height: 50px;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation-delay: 0.5s;
  position: relative;
  text-shadow: 2px 2px 10px var(--secondaryColor);
  font-weight: bold;
}

.Logo-Title-Container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 64px; */
  padding: 0 16px;
  /* backdrop-filter: blur(5px); */
  max-width: 80%;
  min-width: 80%;
  margin-top: 64px;
  flex-direction: column;
}

.Logo-Title-Container svg {
  /* max-height: 300px;
  max-width: 300px; */
  max-height: 400px;
  max-width: 400px;
  min-width: 300px;
  min-height: 300px;
  transition: 0ms all ease-in;
}

.App-Button-Circle:hover {
  transform: scale(1.2);
  transition: 0.25s all ease-out;
  animation: none;
  bottom: 16px;
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.App-Section {
  min-width: 100%;
  max-width: 100%;
}

.App-Section-Background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  z-index: -1;
  position: fixed;

}

.App-Section-Home {
  background-image: url(../../assets/yogame1.jpg);
}

.PresentationBG {
  background-image: url(../../assets/PresentationBG.jpg);

}

.ComplementsBG {
  background-image: url(../../assets/ComplementsBG.jpg);

}

.MakeupBG {
  background-image: url(../../assets/MakeupBG.jpg);

}

.MassageBG {
  background-image: url(../../assets/MassageBG.jpg);

}

.MiamBG {
  background-image: url(../../assets/MiamBG.jpg);

}

.YogaBG {
  background-image: url(../../assets/YogaBG.jpg);
}

.Mobile-App-Contacts {
  display: none;
}

.Logo-Title-Container .Title {
  margin-bottom: 12px;
}

/* Custom, iPhone Retina */


/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  div#App-First-Screen {
    margin: 0;
    width: 100%;
  }

  .App-First-Screen-Contents {
    flex-direction: column;
  }

  .App-Logo-Home-Ctn {
    max-width: 200px;
    margin-bottom: 32px;
  }

  .BlurRight {
    display: none;
  }

  ul {
    text-align: initial;
    align-items: flex-start
  }

  .App-Main-Title-Container {
    max-width: 100%;
    min-width: 100%;

  }

  .BlurLeft {
    display: none;
  }

  .Description-Container {
    min-width: 100%;
    max-width: 100%;
  }

  .App-Tiles-Container {
    width: 100%;
    padding: 0;

  }
}

@media only screen and (max-width : 480px) {
  ul {
    text-align: initial;
    align-items: flex-start
  }

  .BlurRight {
    display: none;
  }

  .App-Main-Title-Container {
    max-width: 100%;
    min-width: 100%;

  }

  .Tile {
    min-width: 90% !important;
    width: 90% !important;
    box-shadow: 2px 2Px 10px rgba(0, 0, 0, 0.5);
  }

  .App-Tiles-Container {
    display: flex;
    flex-direction: row;
    overflow: auto;
    flex-wrap: nowrap;
    padding: 0;
  }

  .Social-Link {
    border-radius: 0;
    flex: 1 !important;
    margin: 0;
  }

  .BlurLeft {
    display: none;
  }

  .Description-Container {
    min-width: 100%;
    max-width: 100%;
  }

  .App-Tiles-Container {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
  }

  .Logo-Title-Container {
    flex-direction: column;
    text-align: center;
    flex-direction: column-reverse;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .Logo-Title-Container .Title {
    font-size: 24px;
    line-height: 32px;
    margin-top: 16px;

  }

  .Logo-Title-Container svg {
    max-width: 200px;
    max-height: 200px;
  }

  .Socials-Container .Socials-Item-Container {
    position: absolute;
    top: 0;
    z-index: 100;
    background: var(--primaryColorOpacity);
    width: 100%;
    align-items: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  }

  .App-Header-Title {
    margin-left: 8px;
    text-align: center;
    font-size: 22px;
  }

  .App-BG-Blur {
    width: 100%;
    background-size: cover;
  }

  .App-Header {
    display: none;
  }

  img.App-Header-Logo {
    width: 30%;
  }

  div#App-First-Screen {
    width: 100%;
    height: auto;
    min-height: 100vh;
  }

  .App-First-Screen-Contents {
    margin-top: 32px;
    flex-direction: column;
    max-width: 100%;
  }

  .App-Logo-Home-Ctn {
    max-width: 96px;
  }

  .App-Main-Title {
    font-size: 26px;
    line-height: 26px;
  }

  .App-Main-Subtitle {
    font-size: 18px;
    line-height: 18px;
    height: 18px;
  }

  .App-Main-Legend {
    width: 100%;
    margin-top: 32px;
  }

  .App-Button-Circle {
    display: none
  }

  .Description-Text {
    padding: 8px 16px;
  }

  .Mobile-App-Contacts {
    display: initial;
    position: sticky;
    top: 0;
    z-index: 10000;
    animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
}

/* Medium Devices, Desktops */
/* @media only screen and (max-width : 992px) {} */

.translateLeftArm {
  animation: translateLeftArm 1s ease-in infinite alternate-reverse;
}

@keyframes translateLeftArm {
  from {
    transform: translate(-227.331px, -50px);
  }

  to {
    transform: translate(-230.331px, -38px);
  }
}

.translateRightArm {
  animation: translateRightArm 0.75s ease-in infinite alternate-reverse;
}

@keyframes translateRightArm {
  from {
    transform: translate(-227.331px, -44px);

  }

  to {
    transform: translate(-220.331px, -38px);
  }
}

.translateYBottom {
  animation: translateYBottom 1.2s ease-in infinite alternate-reverse;
}

@keyframes translateYBottom {
  from {
    transform: translate(-227.331px, -44px);

  }

  to {
    transform: translate(-227.331px, -33px);

  }
}

.rotating360 {
  animation: rotate360 2s ease-in infinite reverse;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.translateWind {
  animation: translateWind 1.2s ease-in infinite alternate-reverse;
}

@keyframes translateWind {
  to {
    transform: translate(0px, 0px);

  }

  from {
    transform: translate(-10px, -20px);

  }
}

.translateXBottom {
  animation: translateXBottom 1.2s ease-in infinite alternate-reverse;
}

@keyframes translateXBottom {
  from {
    transform: translate(-227.331px, -44px);

  }

  to {
    transform: translate(-215.331px, -44px);

  }
}

.levitate {
  animation: levitate 1.2s ease-in infinite alternate-reverse;
}

@keyframes levitate {
  from {
    transform: translate(0, -103.51px);


  }

  to {
    transform: translate(0, -80.51px);

  }
}

.growCircle {
  animation: growCircle 2s ease-in infinite alternate-reverse;
}

@keyframes growCircle {
  from {
    r: 38;
    opacity: 0.25;
  }

  to {
    r: 76;
    opacity: 1;
  }
}

.windTransformation {
  animation: windTransformation 1s ease-out infinite alternate-reverse;
}

@keyframes windTransformation {
  from {
    transform: skewX(0deg);
  }

  to {
    transform: skewX(1deg);
  }
}

.massage {
  animation: massage 1s ease-out infinite alternate-reverse;
}

@keyframes massage {
  from {
    transform: translate(-182px, -173.419px);

  }

  to {
    transform: translate(-182px, -161.419px);

  }
}

.maquillame {
  animation: maquillame 1s ease-out infinite alternate-reverse;
}

@keyframes maquillame {
  from {
    transform: translate(-134.85px, -48.95px);

  }

  to {
    transform: translate(-134.85px, -61.95px);

  }
}

.darksalmon {
  animation: darksalmon 1s ease-out infinite alternate-reverse;
}

@keyframes darksalmon {
  from {
    fill: rgb(247, 196, 167);
  }

  to {
    fill: darksalmon
  }
}

.cookingAnimaiton {
  animation: cookingAnimaiton 1s ease-out infinite alternate-reverse;
}

@keyframes cookingAnimaiton {
  from {
    transform: translate(0px, 0px);

  }

  to {
    transform: translate(0px, 20px);

  }
}