:root {
  --primaryColor: #f5eddf;
  --primaryColorOpacity: #f5eddfa4;
  --primaryColorOpacity2: #f5eddfd3;
  --secondaryColor: #846342;
  --secondaryColorOpacity: #84634280;
  --secondaryColorOpacity2: #846342af;
  --tertiaryColor: #dcb669;
  --tertiaryColorOpacity: #dcb66980;
  --tertiaryColorOpacity2: #dcb669af;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  background: var(--primaryColor);
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}