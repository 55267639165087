.Loading-Container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/yogame1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}